var site = site || {};
site.productView = site.productView || {};

site.productView.updateBis = function($listNode) {
  var that = {
    $listNode: $listNode
  };
  that.productId = that.$listNode.attr("data-product-id");
  that.$listNode.on("inventory_status_stored", function(e) {
    that.updateBisButtons();
    e.stopPropagation();
  });
  that.updateBisButtons = function() {
    if (Drupal.settings.enableNotifyMe != "undefined" && Drupal.settings.enableNotifyMe) {
      $('.notify_me', that.$listNode).show();
    } else {
      $('.notify_me', that.$listNode).hide();
    }
  };
  that.downPriceUpdate = function(skuData) {
    //Down Price Updated for Shaded Products
    var price2 = (_.isUndefined(skuData.PRICE2)) ? '' : skuData.PRICE2;
    var formattedPrice2 = (_.isUndefined(skuData.formattedPrice2)) ? '' : skuData.formattedPrice2;
    var formattedPrice = (_.isUndefined(skuData.formattedPrice)) ? '' : skuData.formattedPrice;
    var productSize = (_.isUndefined(skuData.PRODUCT_SIZE)) ? '' : skuData.PRODUCT_SIZE;
    var priceNode = $('.quickshop-inline__price-size').not('select');
    var priceLoyaltyNode = $('.js-price-loyalty', priceNode).clone();
    if (price2) {
      productSize = (productSize !== null ? (productSize + '  ') : '');
      if (priceLoyaltyNode && priceLoyaltyNode.length) {
        optionTextHtml = '<span class="product__price--non-sale">' + formattedPrice2 + '</span> ';
        priceNode.html(optionTextHtml);
        optionTextHtml = '<span class="product_brief__price-loyalty on-sale product__price--sale js-price-loyalty">' + formattedPrice + '</span>';
        priceLoyaltyNode.html(formattedPrice).css("display", "inline-block");
        priceLoyaltyNode.addClass('on-sale product__price--sale');
        $('.js-price-loyalty', priceNode).replaceWith(priceLoyaltyNode);
        $('.js-price-size', priceNode).html(productSize);
      } else {
        var optionTextHtml = '<span class="product__price--non-sale">' + formattedPrice2 + '</span>  <span class="on-sale  product__price--sale">' + formattedPrice + '</span>  ' + productSize;
        priceNode.html(optionTextHtml);
      }
    } else {
      if (productSize !== null && productSize !== '') {
        if (priceLoyaltyNode && priceLoyaltyNode.length) {
          priceLoyaltyNode.text(skuData.formattedPrice);
          priceLoyaltyNode.removeClass('on-sale product__price--sale');
          $('.js-price-loyalty', priceNode).replaceWith(priceLoyaltyNode);
          $('.js-price-size', priceNode).html(productSize);
        } else {
          priceNode.html(skuData.formattedPrice + '  ' + productSize);
        }
      } else {
        if (priceLoyaltyNode && priceLoyaltyNode.length) {
          priceLoyaltyNode.text(skuData.formattedPrice);
          priceLoyaltyNode.removeClass('on-sale product__price--sale');
          $('.js-price-loyalty', priceNode).replaceWith(priceLoyaltyNode);
        } else {
          priceNode.html(skuData.formattedPrice);
        }
      }
    }
    if (!priceNode.length) {
      // we explicitly exclude the select element in the above...
      // but we do still need to use it as a basis for some loyalty display purposes
      // specifically, the dynamic display updates when a new size is selected
      priceNode = $('select.quickshop-inline__price-size', document);
    }
    $(document).trigger('productQV:rendered:LoyaltyPrices', priceNode);
  };

  that.updateBisButtons();
  that.$listNode.on('sku:select', function(e, skuData) {
    that.$listNode.attr("data-sku-id", skuData.SKU_ID);
    that.updateBisButtons();
    that.downPriceUpdate(skuData);
    site.setAddToBagShoppableByInventoryStatus(skuData, 'js-add-to-cart');
    e.stopPropagation();
  });
  return that;
};

site.productView.inlineQuickshopMobile = function(args) {
  var that = {};
  $.extend(that, args);
  that.productId = that.productData.PRODUCT_ID;
  that.catId = that.productData.PARENT_CAT_ID;
  that.tagIds = that.catId + '~' + that.productId;
  var enableDiscPrice = Drupal.settings.product_info.show_discount_price;

  var qsTemplate = $('script.inline-template[path="quickshop-inline"]').html();

  that.launch = function() {

    $(document).trigger("MPP:productQV", [that.tagIds]);

    // clear all QS containers on page
    $('.js-quickshop-container').empty();

    //check sku length
    that.productData['first_sku'] = that.productData['skus'][0];
    //var multipleSizes = that.productData.skus_.pluck("PRODUCT_SIZE").uniq().length > 1 ? true : false;
    var multipleSizes = _.pluck(that.productData.skus, 'PRODUCT_SIZE');
    //multipleSizes = _.chain(multipleSizes).uniq().compact();
    multipleSizes = _.uniq(multipleSizes);
    multipleSizes = _.compact(multipleSizes).length > 1 ? true : false;
    if (that.productData.sized && multipleSizes) {
      that.productData['multiple_sizes'] = true;
    } else if ((that.productData.sized && !multipleSizes) || that.productData.shaded) {
      that.productData['multiple_sizes'] = false;
    }

    var skus = that.productData.skus;

    var isEsteeEdit = $('body').hasClass('brand-estee_edit');

    //skintypes
    //var skintypes = _.pluck(that.productData.skus, 'SKIN_TYPE');
    var st = _.filter(that.productData.skus, function(sku) {
      return sku.SKIN_TYPE;
    });
    var skintypes = [];
    $(st).each(function(index, val) {
      skintypes[index] = [val.SKIN_TYPE, val.SKU_BASE_ID];
    });
    if (skintypes.length > 1) {
      var skintypeKey = [];
      for (var i = 0; i < skintypes.length; i++) {
        if (skintypes[i][0] != 0) {
          skintypeKey.push(skintypes[i][0]);
        }
      }
      skintypeKey = _.uniq(skintypeKey);
      var skintypeKeys = [];
      for (var i = 0; i < skintypeKey.length; i++) {
        skintypeKeys.push([skintypeKey[i], '-']);
      }
      for (var x = 0; x < skintypes.length; x++) {
        for (var i = 0; i < skintypeKeys.length; i++) {
          if (skintypes[x][0] == skintypeKeys[i][0]) {
            skintypeKeys[i][1] = skintypeKeys[i][1] + ',' + skintypes[x][1];
          }
        }
      }
      for (var i = 0; i < skintypeKeys.length; i++) {
        skintypeKeys[i][1] = skintypeKeys[i][1].replace("-,", "");
      }
      if (skintypeKeys.length > 1) {
        for (var p = 0; p < skintypeKeys.length; p++) {
          var glue = 'SKINTYPE_' + skintypeKeys[p][0];
          that.productData[glue] = skintypeKeys[p][1];
        }
        that.productData['skintypes'] = 1;
      }
    }


    $(skus).each(function(index, sku) {
      var shadeHex = sku.HEX_VALUE_STRING;
      // explode
      var shadeHex = shadeHex.split(',');
      if (shadeHex.length == 1) {
        sku['SWATCH_TYPE'] = 'single';
        sku['HEX_VALUE_1'] = shadeHex[0];
      } else if (shadeHex.length == 2) {
        sku['SWATCH_TYPE'] = 'duo';
        sku['HEX_VALUE_1'] = shadeHex[0];
        sku['HEX_VALUE_2'] = shadeHex[1];
      } else if (shadeHex.length == 3) {
        sku['SWATCH_TYPE'] = 'trio';
        sku['HEX_VALUE_1'] = shadeHex[0];
        sku['HEX_VALUE_2'] = shadeHex[1];
        sku['HEX_VALUE_3'] = shadeHex[2];
      } else if (shadeHex.length == 5) {
        sku['SWATCH_TYPE'] = 'quint';
        sku['HEX_VALUE_1'] = shadeHex[0];
        sku['HEX_VALUE_2'] = shadeHex[1];
        sku['HEX_VALUE_3'] = shadeHex[2];
        sku['HEX_VALUE_4'] = shadeHex[3];
        sku['HEX_VALUE_5'] = shadeHex[4];
      }
      // misc flag
      var sku_misc_flag = sku.MISC_FLAG;
      if (sku_misc_flag == 1) {
        //sku['misc_flag_sku_1'] = true;
        that.productData['misc_flag_sku_1'] = true;
      } else if (sku_misc_flag == 2) {
        //sku['misc_flag_sku_2'] = true;
        that.productData['misc_flag_sku_2'] = true;
      } else if (sku_misc_flag == 3) {
        //sku['misc_flag_sku_3'] = true;
        that.productData['misc_flag_sku_3'] = true;
      } else if (sku_misc_flag == 94 || sku_misc_flag == 5) {
        //sku['misc_flag_sku_4'] = true;
        that.productData['misc_flag_sku_4'] = true;
      } else if (sku_misc_flag == 15) {
        //sku['misc_flag_sku_5'] = true;
        that.productData['misc_flag_sku_5'] = true;
      } else {
        sku.MISC_FLAG = false;
      }
    });

    var qs_render = Mustache.render(qsTemplate, that.productData);

    var rowIndex = that.$productContainer.data('row-index');
    var $quickshopContainer = $(".js-quickshop-container[data-row-index='" + rowIndex + "']");
    that.$quickshopContainer.html(qs_render);

    var $priceLoyaltyNode = that.$quickshopContainer;
    if (multipleSizes) {
      $priceLoyaltyNode = that.$quickshopContainer.find('.js-size-price-loyalty');
    }

    $(document).trigger('MPP:productQV:rendered', [that.$quickshopContainer]);

    $(document).trigger('productQV:rendered:LoyaltyPrices', [$priceLoyaltyNode]);

    // mpp inventory sort grid
    var $mppGridContainer = $quickshopContainer.parent();
    var mppGridSort = $($mppGridContainer).data('inventory-sort') || false;
    var mppGridSortSku = $($mppGridContainer).data('inventory-sort-sku') || false;

    if (that.productData.shaded) {
      var qsPicker = new site.ShadePicker(that.productData);

      if (!mppGridSort || !mppGridSortSku) {
        var smooshSlider = new site.SmooshSlider({
          containerNode: $('.smoosh-list', that.$quickshopContainer),
          productData: that.productData
        });
      }

      if (isEsteeEdit) {
        //center shades
        site.productView.centerShadePicker($quickshopContainer);
        $(window).on('resize', _.debounce(function() {
          site.productView.centerShadePicker($quickshopContainer);
        }, 100));
      }
    }

    var addCartBtn = site.createAddButton($(".js-add-to-cart[data-product-id='" + that.productId + "']"));
    addCartBtn.updateInvStatus();
    var addFaveBtn = site.addFavoritesButton($(".js-add-to-favorites[data-product-id='" + that.productId + "']"));

    var $invStatusList = $(".js-inv-status-list", that.$quickshopContainer);
    var invStatusList = site.productView.InvStatusList($invStatusList);
    invStatusList.updateInvStatus();

    // Disable Inventory list based on Re-order
    if ($quickshopContainer.find('.js-basic-reorder').hasClass('active')) {
      $quickshopContainer.addClass('qs-basic-reorder');
      $invStatusList.hide();
    } else {
      $quickshopContainer.removeClass('qs-basic-reorder');
      $invStatusList.show();
    }
    var updateBisLists = site.productView.updateBis($invStatusList);
    updateBisLists.updateBisButtons();
    var $sizeSelect = $('select.quickshop-inline__price-size', that.$quickshopContainer);
    var $skintypeSelect = $('select.quickshop-inline__skintype-select', that.$quickshopContainer);
    if ($sizeSelect.length && $skintypeSelect.length) {
      function updateSizeSelect() {
        $('option', $sizeSelect).prop('disabled', true);
        var selectedSkus = $skintypeSelect.find('option:selected').attr('data-skus').split(',');
        $(selectedSkus).each(function(index, val) {
          $('option', $sizeSelect).filter("[data-sku-base-id=" + val + "]").prop('disabled', false);
        });
        var $sizeSelectClone = $sizeSelect.clone();
        $sizeSelectClone.removeClass('reference');
        if ($('.sizeSelectClone').length) {
          $('.sizeSelectClone').remove();
        }
        $sizeSelectClone.addClass('sizeSelectClone');
        $('option:disabled', $sizeSelectClone).remove();
        $sizeSelect.parent().append($sizeSelectClone);
        $('.sizeSelectClone').on('change.size', function(event) {
          var selectedSku = $(this).find('option:selected').attr('data-sku-base-id');
          var skuData = _.find(skus, function(sku) {
            return sku.SKU_BASE_ID == selectedSku;
          });
          site.skuSelect(skuData);
          site.setAddToBagShoppableByInventoryStatus(skuData, 'js-add-to-cart');
        });
        $('.sizeSelectClone').trigger('change.size');
      }
      updateSizeSelect();
    } else if ($sizeSelect.length && !$skintypeSelect.length) {
      $sizeSelect.removeClass('reference');
      $sizeSelect.off('change.size');

      function updateRetailPriceValue(ele) {
        var retailPriceVal = ele.find('option:selected').attr('data-retail-price');
        ele.prev(".quickshop-inline__retail-price.js-retail-price").html(retailPriceVal);
      }
      if (enableDiscPrice) {
        updateRetailPriceValue($sizeSelect);
      }
      $sizeSelect.on('change.size', function(event) {
        var optionTextHtml;
        var strikeoutPriceNode;
        var selectedSku = $(this).find('option:selected').attr('data-sku-base-id');
        // To be update on mobile retail price value
        if (enableDiscPrice) {
          updateRetailPriceValue($sizeSelect);
        }
        $(this).prev('.select-markdown').remove();
        if ($(this).find('option:selected').hasClass('option-discount-sale')) {
          var selectedElement = $(this).find('option:selected');
          var optionTextHtml = selectedElement.attr("data-markdown-price");
          $(this).before(optionTextHtml);
        }

        var skuData = _.find(skus, function(sku) {
          return sku.SKU_BASE_ID == selectedSku;
        });
        site.skuSelect(skuData);
        site.setAddToBagShoppableByInventoryStatus(skuData, 'js-add-to-cart');
        strikeoutPriceNode = $(this).siblings('.js-product-formatted-price');

        if (strikeoutPriceNode.length) {
          if (skuData.formattedPrice2) {
            optionTextHtml =
            '<span class="product__price--non-sale">' +
              skuData.formattedPrice2 +
            '</span><span class="on-sale  product__price--sale">' +
              skuData.formattedPrice +
            '</span>';
          } else {
            optionTextHtml =
            '<span class="product-price">' +
              skuData.formattedPrice +
            '</span>';
          }
          strikeoutPriceNode.html(optionTextHtml);
        }
        var installmentPrice = _.isUndefined(skuData.formattedInstallmentPrice) ? '' : skuData.formattedInstallmentPrice;
        var $installmentNode = $(this).siblings(".js-product-price-installment");
        if ($installmentNode.length) {
          $installmentNode.html(installmentPrice);
        }
      });
    }

    // only use skintype as reference, set sku from size select
    $skintypeSelect.change(function(event) {
      updateSizeSelect();
      if ($sizeSelect.length) {
        updateSizeSelect();
        // inventory status update/reorder skin type selects
        var selectInvSorted = $sizeSelect.find('option').data('inventory') || false;
        if (!!selectInvSorted) {
          $(document).trigger('refresh_size_select');
        }
      } else {
        var selectedSku = $(this).find('option:selected').attr('data-skus');
        var skuData = _.find(skus, function(sku) {
          return sku.SKU_BASE_ID == selectedSku;
        });
        site.skuSelect(skuData);
      }
    });

    // if size exists
    if ($sizeSelect.length) {
      // select first sku after inventory sorted
      $sizeSelect.one('refresh_size_select', function(event) {
        var selectedSku = $(this).find('option:selected').not(':disabled').attr('data-sku-base-id');
        var selectedSkuInventory = $(this).find('option:selected').not(':disabled').attr('data-inventory');
        if ($(this).next().length) {
          var $skinTypeSelect = $(this).next();
          $('option:disabled', $skinTypeSelect).not(':selected').remove();
        }
        if (!selectedSku || (selectedSkuInventory == 1) || (selectedSkuInventory == 3)) {
          return;
        }
        if (selectedSkuInventory != 1) {
          var firstShoppable = $(this).find("option[data-inventory='1']").not(':disabled');
          var $firstShoppableObj = $('option', $sizeSelect).filter("option[data-inventory='1']").not(':disabled');
          if (!firstShoppable.length) {
            return;
          } else {
            var firstShoppableInv = $(firstShoppable).attr('data-sku-base-id');
            if (!firstShoppableInv) {
              return;
            }
            var $firstShoppableInvObj = $('option', $sizeSelect).filter("[data-sku-base-id=" + firstShoppableInv + "]").not(':disabled');
            var skuDataInv = _.find(that.productData.skus, function(sku) {
              return sku.SKU_BASE_ID == firstShoppableInv;
            });
            site.skuSelect(skuDataInv);
            $($firstShoppableInvObj).prop('selected', true);
            site.skuSelect(skuDataInv);
          }
        } else {
          //$sizeSelect.trigger('change')
        }
      });

    }

    if (that.position == 1) {
      that.$quickshopContainer.addClass('quickshop-inline--second');
    } else {
      that.$quickshopContainer.removeClass('quickshop-inline--second');
    }
    $(this).hide();
    // that.$productContainer.find('.js-quickshop-close').removeClass('hidden');
    //that.$quickshopContainer.show(); //show using disply: list-item
    that.$quickshopContainer.css('display', 'block');

    // scroll to top of quickshop (actually, make the 'close' button visible)
    $.scrollTo(that.$quickshopContainer, 1000, {
      offset: -135
    });

    // update sizeSelects and shade picker w L2 products order
    if (!!mppGridSort && !!mppGridSortSku) {
      site.productView.updateSwatchSizeInvStatus(that.productData);
    }
  }; // end launch

  that.close = function() {
    $('.js-quickshop-container').empty();
  };

  return that;
};

site.productView.centerShadePicker = function() {
  $('.js-shade-picker').removeAttr('style');
  $('.js-shade-picker').css('padding-left', 0);
  var shadePickerWidth = $('.js-shade-picker').width();
  var $swatches = $('.shade-picker__swatches');
  $swatches.removeAttr('style'); //reset previous
  var swatchWidth = $('.shade-picker__swatches').eq(0).outerWidth(true);

  // how many should fit
  var swatchInRow = Math.floor(shadePickerWidth / swatchWidth); //last one will be pushed to next row, hence floor
  shadePickerWidth = swatchInRow * swatchWidth;
  $('.js-shade-picker').width(shadePickerWidth);
  var rowCount = $swatches.length / swatchInRow; // i.e: 1.3333
  var swatchesWidth = $swatches.length * swatchWidth;
  if (swatchesWidth < shadePickerWidth) {
    var shadePickerPadding = (shadePickerWidth - swatchesWidth) / 2 + 'px';
    $('.js-shade-picker').css('padding-left', shadePickerPadding);
  }

  //deal with last row
  var hasRemainder = (rowCount % 1 != 0) ? 1 : 0;
  if (rowCount > 1 && hasRemainder) {
    // find first swatch of last row
    var previousRows = Math.floor(rowCount);
    var lastRowStarts = swatchInRow * previousRows;
    var $lastRowFirstSwatch = $swatches.eq(lastRowStarts);
    var remainingSwatches = $swatches.length - lastRowStarts;
    var lastRowPadding = Math.ceil((shadePickerWidth - (swatchWidth * remainingSwatches)) / 2) + 'px';
    $lastRowFirstSwatch.css('margin-left', lastRowPadding);
  }
};

// reordering SKUS (smoosh/shades/sizes) by inventory status L2
site.productView.updateSwatchSizeInvStatus = function(products) {

  var product = products;
  if (!$(product).length) {
    return;
  }

  var skus = product.skus;

  var $shadePicker = $(".js-shade-picker[data-product-id='" + product.PRODUCT_ID + "']");
  var $smooshList = $(".smoosh-list[data-product-id='" + product.PRODUCT_ID + "']");
  var $shadeSelect = $("select.js-sku-menu[data-product-id='" + product.PRODUCT_ID + "']");
  var $sizeSelect = $("select.quickshop-inline__price-size[data-product-id='" + product.PRODUCT_ID + "']");

  // find l2 skus
  var l2Skus = [];
  $.each(skus, function(index, sku) {
    var skuBaseID = $(document).data(sku.SKU_ID);
    l2Skus.push(skuBaseID);
  });

  if (l2Skus.length) {
    try {
      l2Skus = _.sortBy(l2Skus, 'INVENTORY_STATUS');
    } catch (err) {
      return;
    }

  }

  var inventoryOrder = {};

  // shade picker / swatches
  if ($shadePicker.length > 0) {
    $.each(l2Skus, function(index, sku) {
      // update shade picker
      var skuBaseID = sku.SKU_ID;
      skuBaseID = skuBaseID.replace('SKU', '');
      var $swatch = $(".swatch[data-sku-base-id='" + skuBaseID + "']").parent('.swatches--single, .swatches--quint');
      $swatch.attr('data-inventory', sku.INVENTORY_STATUS);
      if (inventoryOrder.hasOwnProperty(sku.INVENTORY_STATUS)) {
        inventoryOrder[sku.INVENTORY_STATUS].push($swatch);
      } else {
        inventoryOrder[sku.INVENTORY_STATUS] = [$swatch];
      }
    });
    //push inventory greater than one to the bottom of the list
    for (var key in inventoryOrder) {
      if ((parseInt(key) !== 1) && (parseInt(key) !== 3)) {
        $.each(inventoryOrder[key], function(index, prod) {
          $shadePicker.append(prod);
        });
      }
    }
  }
  // grab select order from shadepicker
  if ($shadePicker.length > 0) {
    var $swatchesSorted = $('.swatch', $shadePicker);
    var skuOrder = [];
    $swatchesSorted.each(function(index, swatch) {
      skuOrder.push($(swatch).attr('data-sku-base-id'));
    });

    // reorder shade select
    if ($shadeSelect.length > 0) {
      $.each(skuOrder, function(index, skuBaseID) {
        $("option[data-sku-base-id='" + skuBaseID + "']", $shadeSelect).appendTo($shadeSelect);
      });
    }

    // reorder smoosh
    if ($smooshList.length > 0) {
      $.each(skuOrder, function(index, skuBaseID) {
        $(".smoosh-list-item[data-sku-base-id='" + skuBaseID + "']", $smooshList).appendTo($smooshList);
      });
      // initialize slick for smoosh
      var smooshSlider = new site.SmooshSlider({
        containerNode: $smooshList,
        productData: product
      });
    }

    // trigger first sku/swatch in shade picker
    var $firstSwatch = $(".swatch[data-sku-base-id='" + skuOrder[0] + "']");
    $(document).on('smoosh_slick_init_ready', $firstSwatch, function() {
      $firstSwatch.trigger('click');
    });

  } //shadePicker

  // size selects
  if ($sizeSelect.length > 0) {
    $.each(l2Skus, function(index, sku) {
      // update size dropdown
      var skuBaseID = sku.SKU_ID;
      skuBaseID = skuBaseID.replace('SKU', '');
      var $size = $("option[data-sku-base-id='" + skuBaseID + "']", $sizeSelect);
      $size.attr('data-inventory', sku.INVENTORY_STATUS);
      if (inventoryOrder.hasOwnProperty(sku.INVENTORY_STATUS)) {
        inventoryOrder[sku.INVENTORY_STATUS].push($size);
      } else {
        inventoryOrder[sku.INVENTORY_STATUS] = [$size];
      }
    });
    //push inventory greater than one to the bottom of the list
    for (var key in inventoryOrder) {
      if ((parseInt(key) !== 1) && (parseInt(key) !== 3)) {
        $.each(inventoryOrder[key], function(index, prod) {
          $sizeSelect.append(prod);
        });
      }
    }
    // refresh and select first sku
    $($sizeSelect).trigger('refresh_size_select');
  }

};
